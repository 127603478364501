// @flow
import * as React from 'react';
import { useIntl } from 'react-intl';

import type { Query } from '../../../schema.flow';
import type { Props as PageHelmetProps } from '../../helmet/components/PageHelmet';
import useSnackbarContext from '../../material-ui/modules/snackbar/hooks/useSnackbarContext';
import usePageContext from '../../page-context/hooks/usePageContext';
import getPreviewData from '../helpers/getPreviewData';
import resolvePath, { type ResolvePathNode } from '../helpers/resolvePath';
import usePreviewData from './usePreviewData';
import messages from '../messages';

export type NodeType = $Shape<{
  ...$Exact<ResolvePathNode>,
  [string]: any,
  data?: ?{
    [string]: any,
    ...$Exact<
      $NonMaybeType<
        $ElementType<
          $NonMaybeType<$ElementType<PageHelmetProps, 'node'>>,
          'data',
        >,
      >,
    >,
  },
}>;

export type Config<Q: {}, N: NodeType> = {|
  staticData: ?Q,
  getNode: (data: ?Q) => ?N,
  snackbarAutoHideDuration?: number,
|};

const getNodePath = ({ node, locale }) => {
  try {
    return resolvePath({ node, locale });
  } catch (error) {
    return undefined;
  }
};

export default function useNodePage<N: NodeType>({
  staticData,
  getNode,
  snackbarAutoHideDuration = 10000,
}: Config<Query, N>) {
  const isPreview = !!getPreviewData();
  const data = usePreviewData(staticData);
  const node = React.useMemo(() => getNode(data), [data]);
  const { locale, translations } = usePageContext();
  const snackbar = useSnackbarContext();
  const intl = useIntl();

  React.useEffect(() => {
    if (isPreview && node) {
      snackbar.show({
        message: intl.formatMessage(messages.preview.nodePageInfo, {
          path: getNodePath({ node, locale }),
          translationsCount:
            translations && typeof translations === 'object'
              ? Object.values(translations).length
              : 0,
        }),
        autoHideDuration: snackbarAutoHideDuration,
        variant: 'info',
      });
    }
  }, [isPreview, node]);

  return {
    isPreview,
    data,
    node,
  };
}
