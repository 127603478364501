// @flow
import * as React from 'react';
import { graphql } from 'gatsby';
import compose from 'recompose/compose';
import withStyles, {
  type WithStyles,
} from '@plugins/material-ui/hocs/withStyles';
import type { Theme } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';

import ResPadding from '~plugins/material-ui/components/ResPadding';
import RichText from '~plugins/prismic/components/RichText';
import RichTextTypography from '~plugins/prismic/components/RichTextTypography';

import withNodePage, {
  type WithNodePageProps,
} from '~plugins/prismic/hocs/withNodePage';
import type { PrismicSimplePage } from '~schema';

export type ClassKey = 'root' | 'title' | 'description';

export type Props = {
  ...$Exact<WithStyles<ClassKey>>,
  ...$Exact<WithNodePageProps<PrismicSimplePage>>,
};

const styles = (theme: Theme) => ({
  root: {},
  title: {
    '& h1': {
      marginTop: 0,
      fontWeight: 'initial',
    },
  },
  description: {
    '& h2, & h3, & h4, & h5, & h6': {
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(2),
    },
    '& p:not(:last-child)': {
      paddingBottom: theme.spacing(1),
    },
    '& b, & strong': {
      color: theme.palette.primary.main,
    },
    '& a': {
      color: theme.palette.primary.main,
      textDecoration: 'none',
    },
  },
});

const SimplePage = ({ classes, node }: Props) => {
  return (
    <Container component={ResPadding} vertical className={classes.root}>
      <RichTextTypography
        {...node?.data?.page_title}
        variant="h5"
        className={classes.title}
      />
      <RichText
        {...node?.data?.page_description}
        className={classes.description}
      />
    </Container>
  );
};

export default compose(
  withNodePage<PrismicSimplePage, *>({
    getNode: data => data?.prismicSimplePage,
  }),
  withStyles<ClassKey, *, Props>(styles, { name: 'SimplePage' }),
)(SimplePage);

export const query = graphql`
  query SimplePageQuery($prismicId: ID) {
    prismicSimplePage(prismicId: { eq: $prismicId }) {
      id
      lang
      uid
      first_publication_date
      last_publication_date
      data {
        meta_title {
          text
        }
        meta_description {
          text
        }
        meta_keywords {
          meta_keyword {
            text
          }
        }
        meta_image {
          alt
          url
          dimensions {
            width
            height
          }
        }
        title {
          text
        }
        page_title {
          text
          html
        }
        page_description {
          text
          html
        }
      }
    }
  }
`;
