// @flow
import * as React from 'react';
import compact from 'lodash/compact';

import type {
  PrismicStructuredTextType,
  PrismicImageType,
  PrismicDocument,
  PrismicAuthor,
} from '~schema';

import AppHelmet from './AppHelmet';

type MetaKeywordData = { meta_keyword?: ?{ [string]: any, text?: ?string } };

export type Props = {
  node?: ?{
    ...$Exact<PrismicDocument>,
    data?: ?{
      meta_title?: ?PrismicStructuredTextType,
      meta_description?: ?PrismicStructuredTextType,
      meta_keywords?: ?Array<?MetaKeywordData>,
      meta_image?: ?PrismicImageType,
      author?: ?{ document: PrismicAuthor },
    },
  },
};

const PageHelmet = ({ node, ...props }: Props) => (
  <AppHelmet
    schemaKey={node?.id}
    title={node?.data?.meta_title?.text || undefined}
    description={node?.data?.meta_description?.text || undefined}
    keywords={compact(
      (node?.data?.meta_keywords || []).map(
        (keywordData: ?MetaKeywordData) => keywordData?.meta_keyword?.text,
      ),
    )}
    image={{
      url: node?.data?.meta_image?.url,
      alt: node?.data?.meta_image?.alt,
      width: node?.data?.meta_image?.dimensions?.width
        ? `${node?.data?.meta_image?.dimensions?.width || ''}`
        : undefined,
      height: node?.data?.meta_image?.dimensions?.height
        ? `${node?.data?.meta_image?.dimensions?.height || ''}`
        : undefined,
    }}
    datePublished={node?.first_publication_date}
    dateModified={node?.last_publication_date}
    author={{
      name: node?.data?.author?.document?.data?.name?.text,
      email: node?.data?.author?.document?.data?.email?.text,
    }}
    {...props}
  />
);

PageHelmet.defaultProps = {
  node: undefined,
};

export default PageHelmet;
