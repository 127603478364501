// @flow
import type { MessageDescriptor } from 'react-intl';
import { html } from 'common-tags';

const prefix = 'prismic';

export default {
  preview: {
    nodePageInfo: ({
      id: `${prefix}.preview.nodePageInfo`,
      defaultMessage: html`
        <p>
          This is a page preview for <strong><code>{path}</code></strong>
        </p>
        <p>Considerations for preview pages:</p>
        <ul>
          {translationsCount, plural, =0 {} other {
          <li>page translations will not load</li>
          } }
          <li>preview data will be lost on page change</li>
        </ul>
      `,
      description:
        'Message shown to inform the user that the information being shown comes from a preview',
    }: MessageDescriptor),
    loading: ({
      id: `${prefix}.preview.loading`,
      defaultMessage: 'Loading preview...',
      description: 'Message shown while the preview is loading',
    }: MessageDescriptor),
  },
};
